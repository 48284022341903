<template>
  <div ref="designerParent" class="h-100">
    <Planning
      v-if="workflow.id"
      :elements="workflowElements"
      :workflow="workflow"
      :process="process"
      @isModal="isModal = true"
      @busy="childrenBusy = $event"
      @updateProcess="$event => (process = $event)"
    />
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
// Components
import Loader from "@/components/Tools/Loader.vue";
import Planning from "@/components/Workflows/Designer/Modes/Planning.vue";
import { bus } from "@/main";
import ProcessManager from "@/components/Workflows/processManager";
import Workflows from "@/components/Workflows/Designer/workflows";
import { mapActions } from "vuex";
import {
  REMOVE_CUSTOM_VARIABLES,
  SET_CUSTOM_VARIABLES
} from "@/core/services/store/variables_v1.module";
import { LOAD_ALL_ELEMENTS } from "@/core/services/store/workflowDesigner.module";

export default {
  components: {
    Loader,
    Planning
  },
  props: [],
  data() {
    return {
      isModal: false,
      process: {},
      workflow: {},
      workflowElements: [],
      isBusy: false,
      childrenBusy: true,
      isReady: false,
      busySaving: false,
      designerFullScreenEnabled: false
    };
  },
  watch: {
    isBusy: function () {
      bus.$emit("isBusy", this.isBusy);
    }
  },
  mounted() {
    this.initVariablesStore();
    this.getProcess();
    this.$store.dispatch(LOAD_ALL_ELEMENTS);
  },
  beforeDestroy() {
    this[REMOVE_CUSTOM_VARIABLES]("outputValues");
    this[REMOVE_CUSTOM_VARIABLES]("parameters");
    this[REMOVE_CUSTOM_VARIABLES]("errorValues");
  },
  methods: {
    ...mapActions("variables", [SET_CUSTOM_VARIABLES, REMOVE_CUSTOM_VARIABLES]),
    initVariablesStore() {
      const variableStores = [
        {
          name: "outputValues",
          json: true,
          variables: {},
          prefix: "output",
          showValue: true
        },
        {
          name: "parameters",
          json: true,
          variables: {},
          prefix: "parameters",
          showValue: true
        },
        {
          name: "errorValues",
          json: true,
          variables: {},
          prefix: "error",
          showValue: true
        }
      ];

      for (const variableStore of variableStores) {
        this[SET_CUSTOM_VARIABLES](variableStore);
      }
    },
    getProcess() {
      this.isBusy = true;
      let processId = this.$route.params.id;
      ProcessManager.get(processId)
        .then(response => {
          this.process = response.data;
          this.getWorkflow();
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    getWorkflow() {
      let workflowId = this.process.workflow_id;
      if (!workflowId) return;

      Workflows.get(workflowId)
        .then(response => {
          this.workflow = response.data;
          this.workflowElements = this.workflow.configured_elements ?? [];
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/components/variables.yedi";

.workflow-designer {
  .page-loader {
    position: absolute;
  }
}

.gridstack-remove {
  top: 20px;
  transform: translateX(-50%);
  left: 50%;
  right: auto;

  #trash {
    width: 70px;
    height: 70px;

    &.ui-droppable-active {
      .btn {
        margin-top: 0px;
      }
    }

    &.ui-droppable-hover {
      .btn {
        background-color: #f64e60;

        i {
          color: #ffffff;
        }
      }
    }

    .btn {
      width: 70px;
      height: 70px;
      background-color: #fff;
      margin-top: -200px;
      transition: all 0.3s ease-in-out;
    }
  }
}

.element-icon {
  width: auto;
  width: 100%;
  height: 100%;
}

#grid-stack {
  .grid-stack-item {
    &.active {
      border: 1px dashed;
      border-color: $primary !important;
      border-radius: 6px;
    }

    &.ui-draggable-dragging {
      z-index: 1040;
    }

    > .grid-stack-item-content {
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: visible;

      .element {
        width: 100% !important;
        height: 100% !important;

        &.operator {
          border: none !important;
          width: 100% !important;
          height: 100% !important;

          img {
            width: 100% !important;
            height: 100% !important;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
